<template>
	<v-row no-gutters>
		<v-col cols="12" sm="12" lg="5" xl="4">
			<v-container class="min-height-full d-flex flex-column left-container">
				<v-card class="elevation-0 form-card">
					<div class="title title-container">
						<img class="logo mb-9" :src="logoUrl" />
						<div class="title title-container">
							<h1 class="mb-5">Connexion à mon compte</h1>
							<h4 class="text-uppercase pl-1 font-weight-regular">Identifiant ou code d'accès sso</h4>
						</div>
					</div>
					<v-card-text v-if="error" class="text-center">
						<v-alert type="error">{{ errorMessage }}</v-alert>
					</v-card-text>
					<v-form @submit.prevent class="mt-10">
						<v-text-field
							v-model="identifier"
							hide-details
							label="Votre code"
							required
							outlined
							color="black"
						></v-text-field>
						<v-checkbox v-model="checkbox">
							<template v-slot:label>
								<span class="checkbox-label">J'accepte les CGU d'OrthographIQ</span>
							</template>
						</v-checkbox>
					</v-form>
					<div class="button-validate d-flex mt-4 actions">
						<router-link :to="{ name: 'Login' }" class="mr-5 ortho-text-gray">Retour</router-link>
						<v-btn
							:loading="loading"
							:disabled="disabled"
							@click="onSubmit"
							depressed
							large
							color="#15c39a"
							class="validez hvr-grow"
						>
							<span class="white--text">Connexion</span>
						</v-btn>
					</div>
				</v-card>
			</v-container>
		</v-col>

		<v-col cols="12" sm="0" lg="7" xl="8">
			<div class="right-container">
				<div class="image-container">
					<v-img src="@/assets/images/screenshot.png"></v-img>
				</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import { TopicLogosLocation } from '@/constants/logos.js';

export default {
	name: 'LoginSSO',
	data: () => ({
		identifier: '',
		error: false,
		errorMessage: '',
		checkbox: false,
		loading: false,
		logoUrl: TopicLogosLocation.default,
	}),
	computed: {
		disabled() {
			return this.identifier.trim() === '' || this.checkbox === false || this.loading;
		},
	},
	created() {
		const urlParams = new URLSearchParams(window.location.search);
		const error = urlParams.get('error');
		if (error) {
			this.error = true;
			this.errorMessage = error;
			return;
		}
		const _identifier = urlParams.get('identifier');
		if (_identifier && _identifier !== '') {
			this.identifier = _identifier;
			this.loading = true;
			this.toSsoPath(_identifier);
			return;
		}
	},
	methods: {
		toSsoPath(identifier) {
			window.location.href = `api/auth/saml2?identifier=${identifier}`;
		},
		onSubmit() {
			this.loading = true;
			this.toSsoPath(this.identifier.trim());
		},
	},
};
</script>

<style lang="scss" scoped>
.left-container {
	justify-content: center;
	align-items: center;
	padding: 1rem;

	.logo {
		max-height: 70px;
		max-width: 100%;
	}

	.checkbox-label {
		color: black;
		font-size: 16px;
		font-weight: 500;
	}

	.actions {
		justify-content: flex-end;
		align-items: center;
	}
}

.right-container {
	width: 100%;
	height: 100%;
	background-color: #2dc7ae;
	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 1263px) {
		display: none;
	}

	.image-container {
		width: 80%;
		.v-image {
			border-radius: 10px;
		}
	}
}
</style>
